<template>
  <div>
    <b-row>
      <b-col :sm="12">
        <v-autocomplete
          hide-details
          v-if="_.size(stores) > 0"
          :items="stores"
          :label="$t('STORE')"
          item-text="name"
          item-value="id"
          v-model="selectedStoreId"
          v-on:change="doSelectStore"
        ></v-autocomplete>
      </b-col>
    </b-row>
    <b-button
      :disabled="selectedStoreId == null"
      class="mr-3"
      variant="primary"
      @click="goNextStep()"
      >{{ $t("CONTINUE") }}</b-button
    >
  </div>
</template>
<script>
import { FETCH_STORES_WAREHOUSES } from "@/core/services/store/shared_store.module";
import { FETCH_LAST_AUDIT_BY_STORE } from "@/modules/stock/store/stock.module";

import { mapGetters, mapState } from "vuex";

import _ from "lodash";
export default {
  name: "StepStockAuditEditSelectStore",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    },
    doGetItems: {
      type: Boolean,
      default: true
    },
    globalForDate: {
      type: String,
      default: ""
    },
    notes: {
      type: String,
      default: ""
    },
    stockAuditId: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    wizardStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      stores: [],
      selectedStoreId: null
    };
  },
  components: {},
  mounted() {
    let vm = this;
    vm.getStores();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "isLoadingSharedStore", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    tempItems: {
      get: function() {
        return this.items;
      },
      set: function(newValue) {
        this.$emit("update:items", newValue);
      }
    },
    tempGlobalForDate: {
      get: function() {
        return this.globalForDate;
      },
      set: function(newValue) {
        this.doSelectStore();
        this.$emit("update:globalForDate", newValue);
      }
    },
    tempNotes: {
      get: function() {
        return this.notes;
      },
      set: function(newValue) {
        this.doSelectStore();
        this.$emit("update:notes", newValue);
      }
    },
    tempStockAuditId: {
      get: function() {
        return this.stockAuditId;
      },
      set: function(newValue) {
        this.doSelectStore();
        this.$emit("update:stockAuditId", newValue);
      }
    },
    tempDoGetItems: {
      get: function() {
        return this.doGetItems;
      },
      set: function(newValue) {
        this.$emit("update:doGetItems", newValue);
      }
    },
    selectedStore() {
      let vm = this;
      return _.find(vm.stores, { id: vm.selectedStoreId });
    },
    storesParams() {
      let storesParams = {};
      return storesParams;
    },
    itemsParams() {
      let vm = this;
      let itemsParams = {
        storeId: vm.selectedStoreId
      };
      return itemsParams;
    }
  },
  watch: {
    storesParams(value) {
      let vm = this;
    }
  },
  methods: {
    getStores() {
      let vm = this;
      let params = this.storesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_STORES_WAREHOUSES, apiParams)
        .then(data => {
          vm.tempFirstLoader = false;
          this.$set(vm, "stores", data.data);
          this.$set(vm, "selectedStoreId", null);
        })
        .catch(response => {
          console.log(response);
        });
    },
    getItems() {
      let vm = this;
      let params = this.itemsParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_LAST_AUDIT_BY_STORE, apiParams)
        .then(data => {
          vm.tempStockAuditId = data.data.id;
          vm.tempGlobalForDate = data.data.forDate;
          vm.tempNotes = data.data.note;
          vm.tempItems = data.data.items;
          vm.tempDoGetItems = false; //prevent reload same items if selectStore is not changed
          this.updateWizardStep(2);
        })
        .catch(response => {
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    doSelectStore() {
      let vm = this;
      vm.tempDoGetItems = true;
      this.$emit("update:selectedStore", vm.selectedStore);
    },
    goNextStep() {
      let vm = this;
      if (vm.tempDoGetItems == true) {
        this.getItems();
      } else {
        this.updateWizardStep(2);
      }
    },
    updateWizardStep(wizardStep) {
      this.$emit("update:wizardStep", wizardStep);
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    handleInput(prop, value) {
      this.$emit("stepperInput", {
        prop: prop,
        value: value
      });
    }
  }
};
</script>
